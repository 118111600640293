<template>
  <div v-cloak>
    <div v-if="apiLoaded">
      <transition appear appear-active-class="fade-enter-active" name="fade">
        <div class="wrap contest">
          <router-link :to="'/config/event'" class="back-link">
            <v-icon>mdi-chevron-left</v-icon>
            Назад
          </router-link>
          <h1>{{ config.event.title }}
            <span>{{ config.event.description }}</span>
            <div style="font-size: 16px">Даты проведения конкурса: {{ config.beginDate | dateFormat }} - {{ config.endDate | dateFormat }}</div>
            <div class="header-select">
              <v-autocomplete
                  v-if="checkRoles(participantRoles)"
                  disabled
                  dense
                  label="Регион"
                  :items="regionsList"
                  item-text="name"
                  item-value="name"
                  v-model="userRegion"
              ></v-autocomplete>
              <v-autocomplete
                  v-else-if="checkRoles(controllerRoles)"
                  dense
                  label="Регион"
                  :items="regionsList"
                  item-text="name"
                  item-value="name"
                  v-model="userRegion"
                  @input="loadFilesTable(userRegion, $route.params.configId)"
              ></v-autocomplete>
              <router-link :to="'/eventRegions/info/'+$route.params.configId">
                <v-btn outlined v-if="checkRoles(controllerRoles)">
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                       style="margin-right: 10px">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V2C20 0.9 19.1 0 18 0ZM18 16H2V2H18V16Z"
                          fill="#0033A0"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M17.41 7.42L15.99 6L12.82 9.17L11.41 7.75L10 9.16L12.82 12L17.41 7.42Z" fill="#0033A0"/>
                    <path d="M8 4H3V6H8V4Z" fill="#0033A0"/>
                    <path d="M8 8H3V10H8V8Z" fill="#0033A0"/>
                    <path d="M8 12H3V14H8V12Z" fill="#0033A0"/>
                  </svg>
                  статус загрузки регионами
                </v-btn>
              </router-link>
            </div>
          </h1>
          <div class="manual-link">
            <div v-for="(staticFile, staticFileIndex) in config.eventJson.staticFiles" :key="'staticFileIndex' + staticFileIndex">
              <a v-if="staticFile.role === 'controller' && checkRoles(controllerRoles)" :href="staticFile.href" download>
                <v-icon>mdi-information-outline</v-icon>
                {{getStaticFileName(staticFile.href)}}
              </a>
              <a v-if="staticFile.role === 'participant' && checkRoles(participantRoles)" :href="staticFile.href" download>
                <v-icon>mdi-information-outline</v-icon>
                {{getStaticFileName(staticFile.href)}}
              </a>
              <a v-if="staticFile.role === 'all' && checkRoles(allRoles)" :href="staticFile.href" download>
                <v-icon>mdi-information-outline</v-icon>
                {{getStaticFileName(staticFile.href)}}
              </a>
            </div>
          </div>
          <v-tabs>
            <v-tab v-for="(nominationTitle, nominationTitleIndex) in config.eventJson.nominations"
                   :key="'nominationTitleIndex' + nominationTitleIndex">
              {{nominationTitle.name}}
            </v-tab>
            <v-tab-item v-for="(nomination, nominationIndex) in config.eventJson.nominations" :key="'nominationIndex' + nominationIndex">
              <h2>{{nomination.name}}</h2>
              <table class="contest-table">
                <tbody>
                <tr v-for="(field, fieldIndex) in nomination.fields" :key="'fieldIndex' + fieldIndex">
                  <td>
                    <p>{{field.name}}</p>
                    <span>{{field.description}}</span>
                  </td>
                  <template v-if="(fieldObjectArray[nominationIndex][fieldIndex] = findFieldObject(field.fieldName))
                  && !switchVisibLinkEditArray[nominationIndex][fieldIndex]">
                    <td v-if="field.type === 'FILE'">
                      <a @click="downloadFile(userRegion,
                      nomination.nominationName,
                      fieldObjectArray[nominationIndex][fieldIndex].fieldName,
                      fieldObjectArray[nominationIndex][fieldIndex].originalName)">
                        {{ truncateStr(fieldObjectArray[nominationIndex][fieldIndex].originalName, 45) }}
                      </a>
                      <span>загружено {{ new Date(fieldObjectArray[nominationIndex][fieldIndex].createdDateTime) | dateTimeFormat}} мск</span>
                    </td>
                    <td v-else-if="field.type === 'LINK'">
                      <a :href="fieldObjectArray[nominationIndex][fieldIndex].name" target="_blank" class="external-link">
                        {{ truncateStr(fieldObjectArray[nominationIndex][fieldIndex].name, 45) }}
                      </a>
                      <span>сохранено {{ new Date(fieldObjectArray[nominationIndex][fieldIndex].createdDateTime) | dateTimeFormat}} мск</span>
                    </td>
                    <td v-else-if="field.type === 'TEXT'">
                      <div>{{ fieldObjectArray[nominationIndex][fieldIndex].name }}</div>
                      <span>сохранено {{ new Date(fieldObjectArray[nominationIndex][fieldIndex].createdDateTime) | dateTimeFormat}} мск</span>
                    </td>
                    <td>
                      <div v-if="checkRoles(participantRoles) && allowToUpdate" class="edit-menu">
                        <div class="edit-menu-icon">
                          <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
                        </div>
                        <div class="edit-menu-popup">
                          <a v-if="field.type === 'FILE'" @click="downloadFile(userRegion,
                           nomination.nominationName,
                           fieldObjectArray[nominationIndex][fieldIndex].fieldName,
                           fieldObjectArray[nominationIndex][fieldIndex].originalName)">
                            <v-icon>mdi-tray-arrow-down</v-icon>
                            Скачать
                          </a>
                          <template v-if="field.type === 'FILE'">
                            <input type="file" :ref="'PUT' + field.fieldName" style="display: none"
                                   @change="uploadFile('PUT', userRegion, nomination.nominationName, field.fieldName)">
                            <a @click="$refs['PUT' + field.fieldName][0].click()">
                              <v-icon>mdi-reload</v-icon>
                              Заменить
                            </a>
                          </template>
                          <template v-else-if="field.type === 'LINK' || field.type === 'TEXT'">
                            <a @click="switchVisibLinkEditArray[nominationIndex][fieldIndex] = true; $forceUpdate()">
                              <v-icon>mdi-note-edit-outline</v-icon>
                              Изменить
                            </a>
                          </template>
                          <a @click="deleteFile(userRegion,
                          nomination.nominationName,
                          fieldObjectArray[nominationIndex][fieldIndex].fieldName); linkOrTextArray[nominationIndex][fieldIndex] = null">
                            <v-icon>mdi-delete-outline</v-icon>
                            Удалить
                          </a>
                        </div>
                      </div>
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      <template v-if="checkRoles(participantRoles) && allowToUpdate">
                        <template v-if="field.type === 'FILE'">
                          <input type="file" :ref="'POST' + field.fieldName" style="display: none"
                                 @change="uploadFile('POST', userRegion, nomination.nominationName, field.fieldName)">
                          <a @click="$refs['POST' + field.fieldName][0].click()" class="upload-link">Загрузить</a>
                        </template>
                        <template v-else-if="field.type === 'LINK' || field.type === 'TEXT'">
                          <v-text-field v-if="fieldObjectArray[nominationIndex][fieldIndex]" dense v-model="fieldObjectArray[nominationIndex][fieldIndex].name" height="45px"></v-text-field>
                          <v-text-field v-else dense v-model="linkOrTextArray[nominationIndex][fieldIndex]" height="45px"></v-text-field>
                        </template>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </td>
                    <td>
                      <template v-if="checkRoles(participantRoles) && allowToUpdate && (field.type === 'LINK' || field.type === 'TEXT')">
                        <v-btn v-if="fieldObjectArray[nominationIndex][fieldIndex]"
                               @click="uploadFile('PUT', userRegion, nomination.nominationName, field.fieldName, fieldObjectArray[nominationIndex][fieldIndex].name);
                               switchVisibLinkEditArray[nominationIndex][fieldIndex] = false; $forceUpdate()"
                               max-width="20px" title="Сохранить введённые данные" outlined>
                          <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>
                        <v-btn v-else
                               @click="uploadFile('POST',
                               userRegion,
                               nomination.nominationName,
                               field.fieldName,
                               linkOrTextArray[nominationIndex][fieldIndex])"
                               max-width="20px" title="Сохранить введённые данные" outlined>
                          <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </v-tab-item>
          </v-tabs>
        </div>
      </transition>
    </div>
    <div v-else style="height:2000px"></div>
  </div>
</template>

<script>
import api from "@/modules/api";

export default {
  name: "EventComponent",
  props: {},
  data() {
    return {
      apiLoaded: false,
      filesTable: null,
      userRegion: null,
      allowToUpdate: false, //запрет на редактирование
      fieldObjectArray: [],
      switchVisibLinkEditArray: [],
      linkOrTextArray: [],
      counter: [],
      regionsList: [],
      // contestConfig,
      config: null,
      eventJson: null,
      participantRoles: ['CONTEST_PARTICIPANT'],
      controllerRoles: ['CONTEST_CONTROLLER', 'ROLE_ADMIN'],
      allRoles: [],
    };
  },

  methods: {
    async loadFilesTable(region, configId) {
      let req = await api.get("/event/getList", {
        region: region,
        eventConfigId: configId,
      });
      if (req.ok) {
        this.filesTable = req.payload;
      } else {
        console.log('getList error');
      }
    },

    async getRegion() {
      let req = await api.get("/getRegion", '');
      if (req.ok) {
        this.userRegion = req.payload.region;
      } else {
        console.log('get region error');
      }
    },

    async downloadFile(region, nomination, fieldName, originalFileName) {
      let req = await api.download("/event/get", {
        region: region,
        nomination: nomination,
        fieldName: fieldName,
      });
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = originalFileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    },

    async deleteFile(region, nomination, fieldName) {
      let req = await api.delete("/event/delete", {
        region: region,
        nomination: nomination,
        fieldName: fieldName,
      });
      if (req.ok) {
        console.log('delete ok');
        await this.loadFilesTable(this.userRegion);
      } else {
        console.log('delete error');
      }
    },

    async uploadFile(method, region, nomination, fieldName, text) {
      let formData;
      if (text === undefined) {
        let file = this.$refs[method + fieldName][0].files[0];
        if (file.size > 52428800) {
          alert('Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером');
          this.$refs[method + fieldName][0].value = "";
          return
        }
        formData = new FormData();
        formData.append('file', file);
      } else {
        formData = null
      }

      let path;
      if (method === 'POST') {
        path = "/event/save"
      } else if (method === 'PUT') {
        path = "/event/update"
      }

      let req = await api.postFile(
          path,
          {
            region: region,
            nomination: nomination,
            fieldName: fieldName,
            text: text,
          },
          formData,
          method
      );
      if (req.ok) {
        console.log('upload ok');
        await this.loadFilesTable(this.userRegion);
      } else {
        console.log('upload error', req.error);
      }
    },

    findFieldObject(fieldName) {
      for (let i = 0; i < this.filesTable.length; i++) {
        if (this.filesTable[i].fieldName === fieldName) {
          return this.filesTable[i]
        }
      }
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 10, str.length)
      }
      return str
    },

    //скачивает конфигурацию с бэка
    async loadConfig(id) {
      let req = await this.$getApi("/event/config/getConfig?id=" + id)
      if (req.ok) {
        this.config = req.payload
        this.config.eventJson = JSON.parse(this.config.eventJson)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    checkRoles(userRoles) {
      for (let role of userRoles) {
        if (this.$user.subroles.includes(role)) {
          return true;
        }
      }
      return false;
    },

    getStaticFileName(href) {
      return href.substring(href.lastIndexOf("/") + 1, href.lastIndexOf("."));
    },

    createFieldObjectArray(fieldName) {
      return this.findFieldObject(fieldName);
    },

    checkEventDate() {
      let actualDate = new Date(),
          beginDate = new Date(this.config.beginDate),
          endDate = new Date(this.config.endDate);
      if (!(actualDate >= beginDate && actualDate <= endDate)) {
        this.allowToUpdate = false
      }
    }
  },

  filters: {
    dateTimeFormat: function (date) {
      if (date == null) {
        return ''
      }
      const mdate = new Date(date)
      const options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    },

    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    this.allRoles = this.participantRoles.concat(this.controllerRoles);
    await this.loadConfig(this.$route.params.configId);
    this.regionsList = this.config.event.contestRegions;
    this.regionsList.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (this.checkRoles(this.participantRoles)) {
      this.userRegion = this.$user.region.name
    }
    else if (this.checkRoles(this.controllerRoles)){
      this.userRegion = this.regionsList[0].name
    }
    for (let i = 0; i < this.config.eventJson.nominations.length; i++) {
      this.fieldObjectArray.push([]);
      this.switchVisibLinkEditArray.push([]);
      this.linkOrTextArray.push([]);
    }

    await this.loadFilesTable(this.userRegion, this.$route.params.configId);
    this.apiLoaded = true;
  },
}
</script>

<style lang="scss">
@import "../styles/main.scss";

.contest .v-tabs-bar {
  margin-bottom: 60px !important;
}
</style>

<style lang="scss" scoped>
.header-select {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  .v-select {
    flex-grow: 0;
    width: 520px;
    font-size: 13px;
  }

  .v-btn {
    margin-left: auto;
    height: 56px;
  }

  a {
    text-decoration: none;
  }
}

.manual-link {
  margin-bottom: 40px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      margin-right: 10px;
    }
  }
}

.v-icon {
  color: #0033a0;
}

.v-input {
  font-size: 13px;
}

.contest-table {
  width: 100%;
  border-collapse: collapse;

  td {
    border-bottom: 1px solid #E8E8E9;
    padding: 10px 0;
    font-size: 12px;

    a:not(.upload-link) {
      font-size: 13px;
      text-decoration: none;
    }

    a.external-link {
      text-decoration: underline;
    }

    div {
      font-size: 13px;
      color: #1C1C1F;
      font-style: normal;
      font-weight: 500;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      color: #1C1C1F;
      margin: 0;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #777779;
      display: block;
    }

    .edit-menu {
      height: auto;
      opacity: 1 !important;
      float: right;

      .edit-menu-popup {
        z-index: 999 !important;
        background: #fff !important;
        width: 158px;

        .v-icon {
          margin-right: 10px;
        }

        a:hover {
          background-color: #ebeff8; //rgba(0, 51, 160, 0.1);
        }
      }
    }

    .v-btn {
      min-width: 20px;
      margin-left: 10px;
    }

    &:nth-child(2) {
      text-align: right;
    }

    &:last-child {
      text-align: right;
      width: 30px;
    }
  }
}

</style>
